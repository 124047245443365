import { Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ManageListComponent } from '@features/manage/pages/manage-list/manage-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { CapturumBuilderActionService } from '@capturum/builders/core';
import { LocalStorageService, ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { CapturumListRendererComponent } from '@capturum/builders/list-renderer';
import { Observable, Subject, switchMap, takeUntil } from 'rxjs';
import { ProjectStateService } from '@features/project/services/project-state.service';
import { ProjectHourDialogComponent } from '@features/project-hour/pages/project-hour-dialog/project-hour-dialog.component';
import { filter, first, map, tap } from 'rxjs/operators';
import { DialogService } from 'primeng/dynamicdialog';
import { NgxPermissionsService } from 'ngx-permissions';
import { Project } from '../../../project/interfaces/project.interface';
import { ProjectHourApiService } from '../../services/project-hour-api.service';
import { TableService } from '@shared/services/table.service';
import { ActiveFilters } from '@capturum/ui/dynamic-filters';
import { PrintService } from '@shared/services/print.service';
import { ListOptions } from '@capturum/api';

@Component({
  selector: 'app-project-hour-list',
  templateUrl: './project-hour-list.component.html',
  styleUrls: ['./project-hour-list.component.scss'],
  providers: [TableService],
  encapsulation: ViewEncapsulation.None,
})
export class ProjectHourListComponent extends ManageListComponent implements OnInit, OnDestroy {
  @ViewChild(CapturumListRendererComponent)
  public listRenderer: CapturumListRendererComponent;

  public context$: Observable<{ project_id: string }>;
  public totalHours$: Observable<string>;
  public showPrint: boolean;
  public selectable: boolean;
  public projectId: string;

  private destroy$ = new Subject<boolean>();
  private apiOptions: ListOptions;

  constructor(
    public route: ActivatedRoute,
    router: Router,
    protected actionService: CapturumBuilderActionService,
    protected toastService: ToastService,
    protected translateService: TranslateService,
    private projectStateService: ProjectStateService,
    private dialogService: DialogService,
    private ngxPermissionService: NgxPermissionsService,
    private projectHourApiService: ProjectHourApiService,
    private tableService: TableService,
    private printService: PrintService,
    private localStorageService: LocalStorageService
  ) {
    super(route, router, actionService, toastService, translateService);
  }

  public ngOnInit(): void {
    this.projectId = this.route.parent.snapshot.paramMap.get('id');

    super.ngOnInit();
    this.showPrint = !!this.ngxPermissionService.getPermission('project-hour.export');
    this.selectable = !!this.ngxPermissionService.getPermission('project-hour.manage');

    if (this.selectable) {
      this.context$ = this.projectStateService.getProject().pipe(
        filter(Boolean),
        map((project: Project) => {
          this.projectId = project.id;

          return {
            project_id: project.id,
          };
        }),
        takeUntil(this.destroy$)
      );
    }

    this.actionService
      .getActionExecutionByActionKey('submit')
      .pipe(takeUntil(this.destroy$))
      .subscribe((action) => {
        this.listRenderer.refresh();
      });

    this.totalHours$ = this.tableService.getUpdateTable().pipe(
      map((options) => {
        let apiOptions: ListOptions = {
          ...options,
        };
        const projectFilterExists = options?.filters.some((filter) => {
          return filter.field === 'project_id';
        });
        const project = this.projectStateService.getProjectSnapshot()?.id;

        if (this.ngxPermissionService.getPermission('project-hour.manage') && !projectFilterExists && project) {
          apiOptions = {
            ...apiOptions,
            filters: [
              ...(apiOptions?.filters || []),
              {
                field: 'project_id',
                operator: 'equals',
                value: this.projectStateService.getProjectSnapshot()?.id,
              },
            ],
          };
        }

        const search = options?.filters.find((filter) => {
          return filter.field === 'global_search';
        });

        if (search) {
          apiOptions = {
            ...apiOptions,
            search: [search.value.replace(/^%|%$/g, '')],
          };
        }

        return apiOptions;
      }),
      tap((options: ListOptions) => {
        this.apiOptions = options;
      }),
      switchMap((options) => {
        return this.projectHourApiService.getTotal(options);
      })
    );
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public add(): void {
    const dialog = this.dialogService.open(ProjectHourDialogComponent, {
      header: this.translateService.instant('market_discovery.project-hour.dialog.add.title'),
      styleClass: 'list_project-hours_edit',
    });

    dialog.onClose.pipe(first(), takeUntil(this.destroy$)).subscribe((refresh: boolean) => {
      if (refresh) {
        this.listRenderer.refresh();
      }

      dialog.destroy();
    });
  }

  public handleFilterChange(): void {
    if (this.listRenderer?.filtersComponent?.activeFilters) {
      let activeFilters: ActiveFilters[] = this.listRenderer?.filtersComponent?.activeFilters;

      if (this.listRenderer.currentApiOptions?.search) {
        activeFilters = [
          ...activeFilters,
          {
            field: 'global_search',
            matchMode: 'like',
            value: this.listRenderer.currentApiOptions.search,
          },
        ];
      }

      this.tableService.updateTableByFilters(activeFilters);
    } else {
      this.tableService.resetUpdateTable();
    }
  }

  public exportHours(): void {
    this.toastService.info(
      this.translateService.instant('market_discovery.project-hours.export'),
      this.translateService.instant('market_discovery.project-hours.export.started')
    );

    const sort = this.localStorageService.getItem('_state_list_project-hours');
    let options: ListOptions = { filters: this.apiOptions?.filters, search: this.apiOptions?.search };

    if (sort?.sortField) {
      options = {
        ...options,
        sort: [
          {
            field: sort.sortField,
            direction: sort.sortOrder === -1 ? 'desc' : 'asc',
          },
        ],
      };
    }

    this.projectHourApiService.exportToExcel(options, this.projectId).subscribe();
  }

  public handlePrint(): void {
    this.printService.setPrintBusy(true);
    this.toastService.info(this.translateService.instant('market_discovery.pdf.generated'), '');

    const options = this.listRenderer?.currentApiOptions;

    this.projectHourApiService.exportToPdf(options).subscribe({
      next: (response) => {
        if (response?.finished) {
          this.printService.setPrintBusy(false);
        }
      },
      error: () => {
        this.printService.setPrintBusy(false);
      },
    });
  }
}
